export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export const GET_LINKS = 'GET_LINKS';
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS';
export const GET_LINKS_FAIL = 'GET_LINKS_FAIL';

export const SET_TITLE = 'SET_TITLE';
export const SET_MINI_WEB_APP = 'SET_MINI_WEB_APP';

export const successMessage = (text, position = 'center') => ({
  type: SET_SUCCESS_MESSAGE,
  payload: {
    text,
    position,
  },
});

export const miniWebApp = (init_data, user, telegram) => ({
  type: SET_MINI_WEB_APP,
  payload: {
    init_data,
    user,
    telegram,
  },
});

export const errorMessage = text => ({
  type: SET_ERROR_MESSAGE,
  payload: text,
});

export const toggleModal = modalName => ({
  type: TOGGLE_MODAL,
  payload: { modalName },
});

export const getLinks = () => ({
  type: GET_LINKS,
});

export const setTitle = payload => ({
  type: SET_TITLE,
  payload,
});
