import axios from 'axios';
import { config } from '../config/config';


const http = axios.create({
  baseURL: config.API_ROOT,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

http.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  const language = localStorage.getItem('language') || 'ru';
  config.headers.Authorization = `Bearer ${token}`;
  config.headers['X-LANGUAGE'] = language;
  let userData = sessionStorage.getItem('telegramUser');
  userData = userData ? JSON.parse(userData) : null;
  config.headers['X-CHUSER-ID'] = userData?.id ?? 0;
  return config;
});

const crmHttp = axios.create({
  baseURL: config.API_CRM,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
  },
});

crmHttp.interceptors.request.use(function (config) {
  // const token = localStorage.getItem('token');
  // config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export { http, crmHttp };
