import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../languages/en-EN.json';
import ru from '../languages/ru-RU.json';
import uz from '../languages/uz-UZ.json';

let userData = sessionStorage.getItem('telegramUser');
userData = userData ? JSON.parse(userData) : null;
let langCode = 'ru';
if (userData?.language_code && (userData?.language_code === 'ru' || userData?.language_code === 'en' || userData?.language_code === 'uz')){
  langCode = userData?.language_code;
}
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
    uz: {
      translation: uz,
    },
  },
  lng: localStorage.getItem('language') || langCode,
  fallbackLng: 'ru',
  keySeparator: false,

  interpolation: {
    escapeValue: false,
    interpolation: {
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        return value;
      },
    },
  },
  react: {
    wait: true,
  },
});

export default i18n;
