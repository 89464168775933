export const config = {
  API_ROOT:
    process.env.REACT_APP_STAGE === 'development'
      ? 'https://cabinet.logi.uz'
      : 'https://cabinet.turonexpress.com',
  VERSION: 'v2',
  IS_DEVELOPMENT: process.env.REACT_APP_STAGE === 'development',
  API_CRM:
    process.env.REACT_APP_STAGE === 'development'
      ? 'https://api.logi.uz'
      : 'https://api.turonexpress.com',
};

export const paymentConfig = {
  squareScript:
    // 'https://js.squareupsandbox.com/v2/paymentform',
    process.env.REACT_APP_STAGE === 'development'
      ? 'https://sandbox.web.squarecdn.com/v1/square.js'
      : 'https://web.squarecdn.com/v1/square.js',
  suqareID:
    // 'sandbox-sq0idb-LtWBwGrLg9pUnptTYSD-XA', 
    process.env.REACT_APP_STAGE === 'development'
      ? 'sandbox-sq0idb-LtWBwGrLg9pUnptTYSD-XA'
      : 'sq0idp-9Foio4kKHy1G56qNkReNCg', //'sq0idp-M-yhb4-b2uK_clhM3Av2zw',

  paypalID:
    // 'AesGYi-atUExhJiFNpA250NrYER_RfrQLymFPnvR0O9X7MbyJ7jPNuv-HCcEFWQJCuMfydi8Q5Ya_FFJ',
    process.env.REACT_APP_STAGE === 'development'
      ? 'AesGYi-atUExhJiFNpA250NrYER_RfrQLymFPnvR0O9X7MbyJ7jPNuv-HCcEFWQJCuMfydi8Q5Ya_FFJ'
      : 'ATb9WXkGA2E6EjuqerH5O_nXC5p1R2qJa8oh87gtcXmfGqT3LgklHFBh3P9a8idr3bFB7g6P912n7A5-',
};
